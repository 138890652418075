.logoStyle{
    max-width: 150px;
  }
  


/**Mobile menu styles**/

.mobileMenuBar{
    width:100% !important;
    background-color: #000 !important;
    
  }
  
  
    p, ul, li, div, nav
    {
  
        padding:0;
        margin:0;
    }
  
  
    body
    {
        font-family:Calibri;
    }
  
  
    #menu {
        overflow: auto;
        position:relative;
        z-index:2;
        height: 196px;
    }
  
    
  
    .parent-menu {
        
        min-width:200px;
        float:left;
    }
  
  
    #menu ul
    {
        list-style-type:none;
    }
  
  
    #menu ul li a
    {
  
        padding:12px 15px;
        display:block;
        color:#fff;
        font-size: 12px;
        text-decoration:none;
        margin-top: -7px;
      
    }
  
  
  
        #menu ul li:hover > ul {
  
          left: 162px;
  
          -webkit-transition: left 200ms ease-in;
  
          -moz-transition: left 200ms ease-in;
  
          -ms-transition: left 200ms ease-in;
  
          transition: left 200ms ease-in;
  
      }
  
  
      #menu ul li > ul {
  
          position: absolute;
  
          background-color: #333;
  
          top: 0;
  
          left: -200px;
  
          min-width: 200px;
  
          z-index: -1;
  
          height: 100%;
  
          -webkit-transition: left 200ms ease-in;
  
          -moz-transition: left 200ms ease-in;
  
          -ms-transition: left 200ms ease-in;
  
          transition: left 200ms ease-in;
  
      }
  
  
      #menu ul li > ul li a:hover
  
      {
  
          background-color:#2e2e2e;
      
  
      }
  




      

  nav {
    margin: 0 auto;
  
    padding: 10px 0;
  }
  nav ul {
    list-style: none;
    text-align: left;
    font-family: "Karla", sans-serif !important;
  }
  nav ul li {
    display: flex;
    font-family: "Karla", sans-serif !important;
    
  }
  nav ul li a {
    font-size: 14px;
  
    text-decoration: none;
   
    letter-spacing: 1px;
    color: #fff !important;
    text-transform: none !important;
    font-family: "Karla", sans-serif !important;
  
    margin-top: px;
  }
  nav ul li a,
  nav ul li a:after,
  nav ul li a:before {
    transition: all 0.5s;
    
  }
  nav ul li a:hover {
    color: ;
    text-decoration: none;
  }
  
  /* Keyframes */
  @-webkit-keyframes fill {
    0% {
      width: 0%;
      height: 1px;
    }
    50% {
      width: 100%;
      height: 1px;
    }
    100% {
      width: 100%;
      height: 100%;
      background: #fff;
    }
  }
  




/* stroke */
nav.stroke ul li a,
nav.fill ul li a {
  position: relative;
}
nav.stroke ul li a:after,
nav.fill ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  text-decoration: none;
  color: transparent;
  background: #fff;
  height: 1px;

}
nav.stroke ul li a:hover:after {
  width: 100%;
}

nav.fill ul li a {
  transition: all 2s;
}

nav.fill ul li a:after {
  text-align: left;
  content: ".";
  margin: 0;
  opacity: 0;
}
nav.fill ul li a:hover {
  color: #fff;
  z-index: 1;
}
nav.fill ul li a:hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}



.uk-navbar-dropdown {
    display: none;
    position: absolute;
    z-index: 1020;
    box-sizing: border-box;
    width: 220px;
    
    padding: 25px;
    background: #000 !important;
    color: #666;
    box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
  }
  
  
  /***Drop down Menu**/
  .dropDown li {
    padding-bottom: 10px;
  }

  .headerBar {
    opacity: rgba(0, 0, 0, 0.84)!important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    padding-top: 0px;
    height:120px
}

.uk-navbar-container:not(.uk-navbar-transparent) {
  background: transparent !important;
}
 

/*MOBILE MENU STYLES*/
.nav-area{
  position: relative;
  padding: 0;
  margin: 0;
  background-color: #313131;
  letter-spacing: 2px;
  width:150px;
 
  font-size: 14px;
  line-height: 15px;
  
}

.nav-area a,
.nav-area a:link,
.nav-area a:visited,
.nav-area a:focus{
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.nav-area a:hover{
  color: #247c9d;
  text-decoration: none;
}

.nav-area > li{
  display: block;
  text-align: left;
}

.nav-area > li a{
  padding: 20px 18px;
  display:block
}

.nav-area > li:hover a {
  color: #fff;
}

.nav-area > li:hover{
  background-color: #247c9d !important;
}


.nav-area > li > .sub-nav{
  list-style: none;
  color: #fff;
  top:0;
  left:160px;
  width:180px;
  min-height: 100%;
  text-align: left;
  margin-top: 30px;
  padding: 0;
  z-index: 99;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.nav-area > li:hover .sub-nav{
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  background: #fff;
  color: #2e2e2e;
}

.nav-area ul li:hover:after{
  color: #247c9d;
}

.nav-area .sub-menu{
  width:100%;
  float:left;
  color:#fff;
  margin: 0 0 0 15px;
}

.nav-area .sub-menu ul{
  padding: 0;
  margin: 0;
}


.nav-area .sub-menu ul li{
  padding: 0;
  list-style: none;
  font-size: 12px;
  color:#313131
}


.nav-area .sub-menu h3{
   font-size: 20px;
   padding: 10px 10px;
   font-weight: bold;
   margin-top: 30px 30px 30px 0;
   color:#000;
   background: #313131;
}

.nav-area .sub-menu ul li a{
  display: block;
  padding: 0 0 15px 0;
  color: #247c9d
}

.nav-area .sub-menu ul li a:hover{
  color: #fff
}

.nav-area > li > ul li ul,
.nav-area li > ul li,
.nav-area > li > .sub-nav,
.nav-area > li >ul,
.nav-area > li {

   -webkit-transition: all 0.8s ease-in-out;
   -moz-transition: all 0.8s ease-in-out;
   -ms-transition: all 0.8s ease-in-out;
   -o-transition: all 0.8s ease-in-out;
   transition: all 0.8s ease-in-out;



}



/* Small phones: from 0 to 480px */
@media only screen and (max-width: 480px) {
  
}






