.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

p{
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.009rem;
  line-height: 28px;
}

h1{
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size:50px;

  
}
h2{
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size:40px;

  
}

h3{
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size:30px;

  
}





h6{
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size:16px;
  line-height: 28px;
  letter-spacing: 0.4px
}

.lightText{
  font-family: "Poppins", sans-serif;
  font-weight: 100;
 
}

.btn{
  background-color: #D59406;
  padding: 10px 25px 10px 25px;
  border-radius: 30px;
  color:#fff;
}

.btn-green{
  background-color: #546A02;
  padding: 10px 25px 10px 25px;
  border-radius: 30px;
  color:#fff;
}

.btn-2{
  background-color: #D59406;
  padding: 10px 25px 10px 25px;
  border-radius: 10px;
  color:#fff;
}

.btn-ghost{
  border:#D59406 2px solid;
  padding: 10px 25px 10px 25px;
  border-radius: 30px;
  color:#fff;
}



.btn-readMore{
  background-color: #D59406;
  padding: 5px 15px 5px 15px;
  color: #fff;
}


.LRbtn{
  display: flex;
}

.LRbtn li {
  margin-left:20px;
  color:#fff;
}




.customPaddingLR{
  padding-left: 370px;
  padding-right: 370px;
}


.light{
  color:#fff;
}



.footermenu{
  list-style-type: none;
  margin: 0;
  padding: 0;
 
  color:#fff;

  
}

.footermenu li{
  display: block;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  }

.footermenu a {
  color:#fff;
  text-decoration: none;
}

.footermenu a:hover{
  color: #ffdd00
}

.shiftContactFooter{
  position: relative;
  left: -90px;
}

.icon{
  color:#fff;
}

.customIcon{
  background-color: #fff !important;
  color:#D59406
}





/**Contact Us Form**/

.input-row{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}


.input-row .input-group{
  flex-basis: 45%;
}

input{
  width:100%;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  padding-bottom: 5px;
}

textarea{
  width: 100%;
  border: 1px solide #ccc;
  outline: none;
  padding: 10px;
  box-sizing: border-box;
}

label{
  margin-bottom: 6px;
  display: block;
  color:#1d4344
}

button{
  background: #D59406;
  
}




/* Small phones: from 0 to 480px */
@media only screen and (max-width: 480px) {

  h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 30px;
  }

  h2 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 25px;
  }

  h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 20px;
  }


  p{
    font-size: 16px;
  }

  .modalBtn{
    position: relative;
    top: 20px;
    right: -50px;
  }

  .shiftContactFooter {
    position: relative;
    left: 0;
  }

  .footerHeightMobile {
    height: 950px !important;
  }

  
.customPaddingLR{
  padding-left: 25px;
  padding-right: 25px;
 
}

}