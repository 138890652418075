.left_shift_index_div{
    position: relative;
    left: -220px;
    padding: 50px;
    top:40px;
}
.moveRightFouthHome{
    position: relative;
    right:-180px;
    padding: 50px;
}

.intro_img{
    width: 550px;
    
   
}
.intro_imgRight{
    width: 550px;
    position: relative;
    right:-180px
}

.blurb_img{
    width:120px
}

.TopMargin{
    position: relative;
    top:270px
}

.downloadBtn{
    width:150px;
}

.checkbtn{
    width:35px
}

.h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 30px;
  }


  /* Big tablet to 1200px (widths smaller taht the 1140px row) */
@media only screen and (max-width: 1200px) {


}



/* Small tablet to big tablet: from 768px to 1023px */
@media only screen and (max-width: 1023px) {


}




/* Small phones to small tablets: from 481px to 767px */
@media only screen and (max-width: 767px) {

}


/* Small phones: from 0 to 480px */
@media only screen and (max-width: 480px) {

   


    

      .left_shift_index_div{
        position: relative;
        left: 0px;
        top:-50px;
        padding: 25px !important;
       
    }
 
    .blurb_img {
        width: 68px;
      }

      .curvedBgMobile{
        height: 1230px !important;
      }


      .intro_imgRight {
        width: 350px;
        position: relative;
        right: 0px !important;
      }

      .moveRightFouthHome{
        position: relative;
        right:0px;
        padding: 50px;
    }

    }