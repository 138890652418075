.title_header_pad{
    padding-top: 200px;
}

.custom_paddingLR{
    padding-left: 200px;
    padding-right: 200px;
    padding-bottom: 50px;
    padding-top: 50px;
}

.customIconz{
    width: 40px !important;
    color: #fff;
}

.team_container{
  
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   padding:20px;
   border-bottom-left-radius:20px;
   border-top-right-radius:20px
}

.team_members{
    width:150px
}

.team_role{
    margin-top: -20px;
    
}

.team_members_janet{
    border-radius: 500px;
    width:130px;
}


.
/* Small phones: from 0 to 480px */
@media only screen and (max-width: 480px) {


   .team_members_janet {
        width:130px !important
   }

}